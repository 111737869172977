<template>
  <div class="out-st">
    <v-row no-gutters class="pb-3">
      <v-col md="12">
        <div class="pa-10 pt-5">
          <div class="d-flex justify-space-between pt-4 pb-4">
            <span> Total: {{ filtered.length }} Classes </span>
          </div>
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"
            class="my-10"
          ></v-progress-linear>

          <v-data-table
            v-if="!isLoading"
            :headers="dynamicHeader"
            :items="filtered"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            hide-default-header
            class="elevation-0 spendingTable2"
            fixed-header
            height="60vh"
          >
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th
                    v-for="header in props.headers"
                    :key="header.value"
                    :style="{ textAlign: 'center', minWidth: header.minWidth }"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tr class="fixed-row-filter">
                <td v-for="header in props.headers" :key="header.text" class="headerOueding">
                  <div v-if="filters.hasOwnProperty(header.value)">
                    <Autocomplete
                      v-if="filters.hasOwnProperty(header.value)"
                      @setFilter="setFilter"
                      :styleBorder="'border-bottom'"
                      :keyFilter="header.value"
                      :selectedInit="filters[header.value]"
                      :listItem="groupColumnValueList(header.value)"
                    />
                  </div>
                </td>
              </tr>
            </template>

            <template v-slot:item="{ item, index }">
              <tr>
                <td>
                  <p>{{ index + 1 }}</p>
                </td>
                <td>
                  <p class="link" @click="viewDetailClass(item.classID)">{{ item.classCode }}</p>
                </td>
                <td>
                  <p
                    :style="{ textAlign: 'center' }"
                    v-if="item.qcName === null || item.qcName === ''"
                  >
                    -
                  </p>
                  <p
                    :style="{ textAlign: 'center' }"
                    v-else
                    class="link"
                    @click="viewDetailProfile(item.qc_userID)"
                  >
                    {{ item.qcName }}
                  </p>
                </td>

                <td v-for="reward in rewardTypeList" :key="reward.id">
                  <div style="display: flex; justify-content: space-between">
                    <p style="white-space: nowrap; margin-right: 20px">
                      {{ checkRewardTypeID(reward, item.rewards) }} students
                    </p>
                    <div class="d-flex align-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            size="18"
                            class="mr-2"
                            @click="openDetail(item.classID, reward.id)"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            >visibility
                          </v-icon>
                        </template>
                        <span>View</span>
                      </v-tooltip>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>

          <div color="#fff" class="py-5" style="display: flex; justify-content: space-between">
            <div>
              <v-select
                outlined
                hide-details
                dense
                :value="itemsPerPage"
                :items="[20, 50, 100, 200]"
                @change="itemsPerPage = parseInt($event, 10)"
                style="height: 32px"
              ></v-select>
            </div>

            <div>
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
                elevation="0"
              ></v-pagination>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="overlay" :class="'h'">
      <div class="card-i">
        <v-card :style="{ boxShadow: 'none' }" light>
          <v-card-title class="text-h5" :style="{ fontWeight: 700 }">
            List of students received awards
          </v-card-title>
          <v-divider></v-divider>
          <DialogRewardByClass
            v-if="studentRewardByClass.length > 0"
            :studentRewardByClass="studentRewardByClass"
            :rewardListType="rewardListType"
            :rewardListTypeUpdate="rewardListTypeUpdate"
          />
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <div :class="'mb-4 mr-4'" class="btnNote">
              <v-btn elevation="2" large @click="overlay = false">Cancel</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import Autocomplete from '@/components/Utils/Autocomplete.vue'
import { rewardApi } from '@/api/reward.js'
import { getObjectPermission } from '../../../common/index'
import DialogRewardByClass from './DialogRewardByClass.vue'

export default {
  props: {
    locationID: {},
    fromDate: {},
    toDate: {},
    rewardListTypeUpdate: {},
    rewardListType: {},
    classStatusID: {},
  },
  components: { Autocomplete, DialogRewardByClass },
  beforeMount() {},

  data() {
    return {
      studentRewardByClass: [],
      overlay: false,
      dialogReward: false,
      deleteID: 0,
      dialogImg: false,
      page: 1,
      permision: getObjectPermission(401),
      pageCount: 0,
      itemsPerPage: 100,
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDZsW_sjb-tTvyqY1UFlWuBi9el6mBTNG60A&usqp=CAU',
      rewardSelect: {},
      isLoading: false,

      headers: [
        {
          text: 'No',
          align: 'start',
          value: 'no',
        },
        { text: 'Class code', value: 'classCode', minWidth: '150px' },
        { text: 'QC', value: 'qcName', minWidth: '200px' },
      ],
      rewardList: [],
      rewardTypeList: [],
      centerList: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      classStatus: 3,
      filters: {
        classCode: [],
        qcName: [],
      },
    }
  },

  computed: {
    filtered() {
      // const rewardType = this.rewardTypeList.map(e => e.name.replace(/\s/g, ''))
      return this.rewardList.filter(d => {
        const filters = localStorage.getItem('rewardFilter')
          ? JSON.parse(localStorage.getItem('rewardFilter'))
          : this.filters
        return Object.keys(filters).every(f => {
          return (
            filters[f.toString()].length < 1 ||
            (f === 'qcName'
              ? this.filters[f].reduce((value, el) => {
                  if (d.qcName === null && el === 'Empty') {
                    value = true
                  }
                  if (d.qcName === el) {
                    value = true
                  }
                  return value
                }, false)
              : filters[f.toString()].includes(d[f.toString()]))
          )
        })
      })
    },

    dynamicHeader() {
      const newHeaders = [...this.headers]
      this.rewardTypeList.map(reward => {
        newHeaders.push({
          text: reward.name,
          value: reward.name.replace(/\s/g, ''),
          align: 'start',
        })
        this.filters[reward.name.replace(/\s/g, '')] = []
      })

      return newHeaders
    },
  },
  beforeCreate() {},
  created() {
    this.resetFilter()

    if (localStorage.getItem('rewardFilter')) {
      // this.filters = JSON.parse(localStorage.getItem('rewardFilter'))
      localStorage.removeItem('rewardFilter')
    }

    this.getTypeList()
    this.init()
  },
  watch: {
    locationID: function () {
      this.resetFilter()
      this.getRewardList()
    },
    fromDate: function () {
      this.resetFilter()
      this.getRewardList()
    },
    toDate: function () {
      this.resetFilter()
      this.getRewardList()
    },
    classStatusID: function () {
      this.resetFilter()
      this.getRewardList()
    },
  },
  methods: {
    checkRewardTypeID(reward, rewLt) {
      const rewardFind = rewLt.find(rw => rw.rewardTypeID == reward.id)
      if (rewardFind) {
        return rewardFind.numberOfReward
      } else {
        return 0
      }
    },
    async getTypeList() {
      this.rewardTypeList = await this.rewardListType
    },

    resetFilter() {
      this.filters = {
        classCode: [],
        qcName: [],
        Studentofthemonth: [],
      }
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange['name']]: objectFilterChange.filter,
      }

      localStorage.setItem('rewardFilter', JSON.stringify(this.filters))
      this.rewardList = [...this.rewardList]
    },
    viewDetailClass(classID) {
      this.$router.push({
        name: 'class-management-detail',
        query: { classID: classID },
      })
    },
    viewDetailProfile(userId) {
      this.$router.push({
        name: 'account-management-view-account',
        params: {
          userId: userId,
        },
      })
    },
    async init() {
      this.isLoading = true

      this.classStatus = this.classStatusID < 6 ? this.classStatusID : ''
      await rewardApi
        .getRewardByClass(this.locationID, this.classStatus, this.fromDate, this.toDate)
        .then(res => {
          const typeListFormat = this.rewardListType.map(type => type.name.replace(/\s/g, ''))
          res.forEach(item => {
            item.rewards.forEach(rew => {
              typeListFormat.forEach(type => {
                if (rew.rewardName) {
                  if (rew.rewardName.replace(/\s/g, '') !== type) {
                    item[type] = 0
                  } else {
                    item[rew.rewardName.replace(/\s/g, '')] = rew.numberOfReward
                  }
                } else item[type] = 0
              })
            })
          })
          this.rewardList = [...res]
          this.isLoading = false
        })
    },

    async getRewardList() {
      this.isLoading = true
      this.classStatus = this.classStatusID < 6 ? this.classStatusID : ''
      await rewardApi
        .getRewardByClass(this.locationID, this.classStatus, this.fromDate, this.toDate)
        .then(res => {
          const typeListFormat = this.rewardListType.map(type => type.name.replace(/\s/g, ''))
          res.forEach(item => {
            item.rewards.forEach(rew => {
              typeListFormat.forEach(type => {
                if (rew.rewardName) {
                  if (rew.rewardName.replace(/\s/g, '') !== type) {
                    item[type] = 0
                  } else {
                    item[rew.rewardName.replace(/\s/g, '')] = rew.numberOfReward
                  }
                } else item[type] = 0
              })
            })
          })

          this.rewardList = [...res]
          this.isLoading = false
        })
    },

    groupColumnValueList(val) {
      const rewardType = this.rewardTypeList.map(e => e.name.replace(/\s/g, ''))
      if (val === 'qcName') {
        return this.rewardList.map(d => (d[val] === null ? 'Empty' : d[val])).filter(y => y)
      }
      if (rewardType.includes(val)) {
        return [...new Set(this.rewardList.map(d => d[val]))]
      }
      return this.rewardList.map(d => d[val])
    },

    async openDetail(classID, rewardTypeID) {
      this.overlay = false
      await rewardApi.getStudentRewardByClass(classID, rewardTypeID).then(res => {
        this.studentRewardByClass = res.map(reward => {
          return {
            ...reward,
            rewardDate: dayjs(reward.rewardDate).format('DD/MM/YYYY'),
          }
        })
        this.overlay = true
      })
    },
    _dayjs: dayjs,
  },
}
</script>
<style scoped>
.arch-select > * {
  width: 170px;
  margin-right: 10px !important;
}

.spendingTable2 tbody tr:nth-child(odd) {
  background-color: #bde6fbb0 !important;
}

.dialog {
  position: absolute;
  width: fit-content;
  overflow: hidden;
  height: auto;
  margin: auto;
  width: 100%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link {
  cursor: pointer;

  color: #427df2 !important;
}

.link:hover {
  text-decoration: underline;
  font-weight: 700;
}

.cur {
  cursor: pointer;
}

.m-100 {
  min-width: 100px;
}

.m- .text-center {
  text-align: center;
}

/* //take note */
.h {
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  z-index: 1000;
  left: 0;
}

.card-i {
  width: 90vw;
  position: absolute;
  top: 50%;

  left: 50%;
  transform: translate(-50%, -50%);
}

.btnNote {
  float: right;
  margin-top: 20px;
}

.btnNote button {
  margin-left: 20px;
  min-width: 150px !important;
}
</style>
