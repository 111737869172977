var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"out-st"},[_c('v-row',{staticClass:"pb-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-10 pt-5"},[_c('div',{staticClass:"d-flex justify-space-between pt-4 pb-4"},[_c('span',[_vm._v(" Total: "+_vm._s(_vm.filtered.length)+" Classes ")])]),(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-0 spendingTable2",attrs:{"headers":_vm.dynamicHeader,"items":_vm.filtered,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","hide-default-header":"","fixed-header":"","height":"60vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.value,style:({ textAlign: 'center', minWidth: header.minWidth })},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('td',{key:header.text,staticClass:"headerOueding"},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_c('p',[_vm._v(_vm._s(index + 1))])]),_c('td',[_c('p',{staticClass:"link",on:{"click":function($event){return _vm.viewDetailClass(item.classID)}}},[_vm._v(_vm._s(item.classCode))])]),_c('td',[(item.qcName === null || item.qcName === '')?_c('p',{style:({ textAlign: 'center' })},[_vm._v(" - ")]):_c('p',{staticClass:"link",style:({ textAlign: 'center' }),on:{"click":function($event){return _vm.viewDetailProfile(item.qc_userID)}}},[_vm._v(" "+_vm._s(item.qcName)+" ")])]),_vm._l((_vm.rewardTypeList),function(reward){return _c('td',{key:reward.id},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('p',{staticStyle:{"white-space":"nowrap","margin-right":"20px"}},[_vm._v(" "+_vm._s(_vm.checkRewardTypeID(reward, item.rewards))+" students ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"18","color":"primary"},on:{"click":function($event){return _vm.openDetail(item.classID, reward.id)}}},'v-icon',attrs,false),on),[_vm._v("visibility ")])]}}],null,true)},[_c('span',[_vm._v("View")])])],1)])])})],2)]}}],null,false,105926352)}):_vm._e(),_c('div',{staticClass:"py-5",staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"color":"#fff"}},[_c('div',[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('div',[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)])],1),_c('v-dialog',{class:'h',model:{value:(_vm.overlay),callback:function ($$v) {_vm.overlay=$$v},expression:"overlay"}},[_c('div',{staticClass:"card-i"},[_c('v-card',{style:({ boxShadow: 'none' }),attrs:{"light":""}},[_c('v-card-title',{staticClass:"text-h5",style:({ fontWeight: 700 })},[_vm._v(" List of students received awards ")]),_c('v-divider'),(_vm.studentRewardByClass.length > 0)?_c('DialogRewardByClass',{attrs:{"studentRewardByClass":_vm.studentRewardByClass,"rewardListType":_vm.rewardListType,"rewardListTypeUpdate":_vm.rewardListTypeUpdate}}):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"btnNote",class:'mb-4 mr-4'},[_c('v-btn',{attrs:{"elevation":"2","large":""},on:{"click":function($event){_vm.overlay = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }